import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { Switch, Route } from "react-router-dom";
import firestar from "firestar";
import Home from "./Home";
import Login from "./Login";
import Landing from "./Landing";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/analytics";
import { firebaseConfig } from "./firebase";

const env = process.env.REACT_APP_ENV;

if (firebase.apps.length === 0) {
  const app = firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firestar.init(
    "admin",
    "browser",
    () => firebase.firestore,
    () => firebase.auth,
    () => firebase.storage,
    () => firebase.database,
    env === "emulator"
      ? {
          app: app as any,
          host: "http://localhost",
          port: {
            auth: 9099,
            database: 9000,
            firestore: 8080,
            storage: 9199,
          },
        }
      : undefined
  );
}

export default function App() {
  const [userId, setUserId] = useState<string>();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(onAuthStateChanged);
  }, []);

  if (showLoader === true) {
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View>
      {userId ? (
        <Switch>
          <Route path="/" component={Home} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/login" component={Login} />
        </Switch>
      )}
    </View>
  );

  function onAuthStateChanged() {
    setUserId(firebase.auth().currentUser?.uid);
    setShowLoader(false);
  }
}
