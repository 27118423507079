import React, { useState } from 'react'
import { View } from 'react-native'
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {
   useHistory,
   Switch,
   Route
} from 'react-router-dom'
import Users from './Users';
import AppSettings from './AppSettings';
import BugReports from './BugReports';
import Dashboard from './Dashboard';
import { Helmet } from "react-helmet";
import Playground from './Playground';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import CasinoIcon from '@material-ui/icons/CasinoOutlined';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import BugReportOutlined from '@material-ui/icons/BugReportOutlined';
import GamepadOutlined from '@material-ui/icons/GamepadOutlined';
import PhotoAlbumOutlined from '@material-ui/icons/PhotoAlbumOutlined';
import Chats from './Chats';
import firebase from 'firebase/compat/app';
import Media from './Media';

function MenuItems() {
   return [
      {
         id: '/',
         title: 'Dashboard',
         renderIcon: () => <DashboardIcon />,
         component: Dashboard,
      },
      {
         id: '/users',
         title: 'Users',
         renderIcon: () => <CasinoIcon />,
         component: Users,
      },
      {
         id: '/settings',
         title: 'Settings',
         renderIcon: () => <SettingsOutlined />,
         component: AppSettings,
      },
      {
         id: '/bug-reports',
         title: 'Bug Reports',
         renderIcon: () => <BugReportOutlined />,
         component: BugReports,
      },
      {
         id: '/playground',
         title: 'Playground',
         renderIcon: () => <GamepadOutlined />,
         component: Playground,
      },
      {
         id: '/media',
         title: 'Media',
         renderIcon: () => <PhotoAlbumOutlined />,
         component: Media,
      }
   ]
}

export default function Home() {

   const [drawerOpen, setDrawerOpen] = useState(false)
   const history = useHistory()

   return (
      <View>
         <AppBar position="static">
            <div className="application">
               <Helmet>
                  <title>Mapulator | Admin</title>
               </Helmet>
            </div>
            <Toolbar style={{
               justifyContent: 'space-between'
            }}>
               <View style={{
                  flexDirection: 'row',
                  alignItems: 'center'
               }}>
                  <IconButton onClick={() => setDrawerOpen(true)} edge="start" color="inherit" aria-label="menu">
                     <MenuIcon />
                  </IconButton>
                  <Typography variant="h6">
                     Mapulator Admin
                  </Typography>
               </View>
				<button style={{
					padding: '0.5em 1em'
				}} onClick={() => firebase.auth().signOut()}>Logout</button>
            </Toolbar>
         </AppBar>
         <Drawer
            elevation={1}
            variant='persistent'
            onBackdropClick={() => setDrawerOpen(false)}
            anchor={'left'}
            open={drawerOpen}
            onClose={() => onPressDrawerMenu()}>
            <View style={{
               width: 250,
               flex: 1
            }}>
               <List>
                  {MenuItems().map(({ title, id, renderIcon }, index) => (
                     <div>
                        <ListItem onClick={() => onPressMenu(id)} style={{
                           paddingRight: 50,
                           paddingLeft: 30,
                           paddingTop: 10,
                           paddingBottom: 10,
                        }} button key={id}>
                           <ListItemIcon>
                              {renderIcon()}
                           </ListItemIcon>
                           <ListItemText primary={title} />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
               </List>
            </View>
         </Drawer>
         <Switch>
            {MenuItems().map(({ id, component }) => (
               <Route exact path={id} component={component} />
            ))}
            <Route exact path={'/bug-reports/:chatId'} component={Chats} />
         </Switch>
      </View>
   )

   function onPressMenu(id: string) {
      history.push(id)
      setDrawerOpen(false)
   }

   function onPressDrawerMenu() {
      setDrawerOpen(false)
   }
}
