import React, { useState } from 'react'
import { View, Text } from 'react-native'
import firebase from 'firebase/compat/app';

export default function Login() {

	const [showLoader, setShowLoader] = useState(false)

	if (showLoader === true) {
		return (
			<Text>Loading...</Text>
		)
	}

	return (
		<View style={{
			padding: 10
		}}>
			<Text>Login</Text>
			<button onClick={onPressLogin}>Login with Google</button>
		</View>
	)

	async function onPressLogin() {

		const provider = new firebase.auth.GoogleAuthProvider()
		setShowLoader(true)
		provider.setCustomParameters({ prompt: 'select_account' });
		try {
			const popUpResponse = await firebase.auth().signInWithPopup(provider)
			if (popUpResponse.credential != null) {
				const response = await firebase.auth().signInWithCredential(popUpResponse.credential)
				if (response.user?.email?.includes('mapulator.app') ||
					response.user?.email?.includes('logisian.in')) {
				} else {
					firebase.auth().signOut()
				}
			}
		} catch (error) {
			console.log('Error SignIn with Pop Up', error)
		}
		setShowLoader(false)
	}

}
