import { View, Text } from 'react-native'
import {
	Link
} from 'react-router-dom'

export default function Landing() {
	return (
		<View>
			<Text>Landing</Text>
			<Link to={'/login'}>Login</Link>
		</View>
	)
}