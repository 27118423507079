/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	FlatList, Image, Text, View, TouchableOpacity, Dimensions, ActivityIndicator
} from 'react-native'
import {
	Assets
} from 'mcore'
import LinearProgress from '@material-ui/core/LinearProgress'
import ImageUploading, { ImageListType } from 'react-images-uploading';
import Resizer from 'react-image-file-resizer';
import TextField from './utils/TextField';
import {
	Actions
} from 'firestar'
import { Alert } from './utils/Alert.web';

const screen = Dimensions.get('window')

const styles = {
	button: {
		borderWidth: 1, borderColor: '#dddddd', width: 30, height: 30, backgroundColor: 'white'
	}
}

export default function Media() {

	const [assets, setAssets] = useState<Assets.Asset[]>([])
	const [showLoader, setShowLoader] = useState(false)
	const [newAsset, setNewAsset] = useState<Assets.Asset | Partial<Assets.Asset>>()
	const [paginating, setPaginating] = useState(false)
	const [lastDoc, setLastDoc] = useState<any>()

	useEffect(() => {
		fetchAssets(false)
	}, [])

	useEffect(() => {
		if (newAsset) {
			console.log('New Asset', newAsset)
		}
	}, [newAsset])

	if (showLoader) {
		return <LinearProgress />
	}

	return (
		<View style={{
			flex: 1,
			padding: '1em',
			width: screen.width - 100
		}}>
			<View style={{
				justifyContent: 'space-between',
				alignItems: 'center',
				flexDirection: 'row'
			}}>
				<Text style={{
					textAlign: 'left',
					fontSize: 20
				}}><b>Assets</b></Text>
			</View>
			<br />
			<View style={{
				flex: 1,
				padding: '1em',
				borderWidth: 1,
				borderColor: '#dddddd',
				borderStyle: 'dashed',
				marginVertical: '1em'
			}}>
				<View style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between'
				}}>
					{newAsset?.thumbnailMedium != null && (
						<Image
							style={{
								width: 100,
								height: 100,
								marginRight: '1em'
							}}
							source={{
								uri: newAsset.thumbnailMedium,
							}} />
					)}
					<TextField
						style={{
							flex: 1
						}}
						placeholder='Enter asset name'
						value={newAsset?.name}
						onChangeText={(value) => setNewAsset({
							...newAsset,
							name: value
						})} />
					<ImageUploading
						value={[{
							dataURL: newAsset?.thumbnail ?? ''
						}]}
						onChange={onSelectImage}>
						{({
							onImageUpload,
							onImageRemove,
						}) => {
							return (
								<View style={{
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'row'
								}}>
									{<button
										style={{
											padding: '1em',
											fontSize: 13,
											marginLeft: '1em'
										}}
										onClick={onImageUpload}>SELECT</button>}
									<button
										style={{
											padding: '1em',
											fontSize: 13,
											marginLeft: '1em'
										}}
										disabled={(newAsset?.name ?? '').length === 0 || newAsset?.thumbnail === null}
										onClick={onPressUpload}>UPLOAD</button>
								</View>
							)
						}}
					</ImageUploading>
				</View>
			</View>
			<FlatList
				style={{
					marginLeft: -10,
					width: screen.width,
					height: screen.height
				}}
				onEndReachedThreshold={0.5}
				onEndReached={({ distanceFromEnd }) => {
					if (paginating === false && lastDoc != null) {
						fetchAssets(true)
					}
				}}
				ListFooterComponent={() => (
					<View style={{
						paddingVertical: 20,
						justifyContent: 'center',
						alignItems: 'center'
					}}>
						{paginating === true && <ActivityIndicator />}
					</View>
				)}
				numColumns={4}
				renderItem={renderItem}
				data={assets} />
		</View>
	)

	function renderItem({ item }: { item: Assets.Asset }) {
		return (
			<ImageItem item={item} />
		)
	}


	async function onSelectImage(value: ImageListType, addUpdatedIndex?: Array<number>) {

		if ((value ?? []).length > 0) {

			const file = value![0].file as any
			const data = value![0].dataURL as any

			let thumbnail: any
			let thumbnailMedium: any

			Resizer.imageFileResizer(file, 128, 128, 'webp', 100, 0,
				async (resizedData) => {

					thumbnail = resizedData ?? null

					Resizer.imageFileResizer(file, 256, 256, 'webp', 100, 0,
						async (resizedData) => {

							thumbnailMedium = resizedData ?? null

							Resizer.imageFileResizer(file, 512, 512, 'webp', 100, 0,
								async (resizedData) => {

									setNewAsset({
										thumbnail,
										thumbnailMedium,
										thumbnailLarge: (resizedData ?? null) as any,
										originalImage: data,
									})

								}, 'base64')

						}, 'base64')

				}, 'base64')

		}
	}

	async function fetchAssets(paginating: boolean) {
		if (paginating === true) {
			setPaginating(paginating)
		} else {
			setShowLoader(true)
		}
		try {
			const response = await Assets.getAll({
				limit: 10,
				orderBy: {
					fieldPath: 'createdAt',
					directionStr: 'desc',
				},
				lastDoc: paginating === true ? lastDoc : undefined
			})
			setLastDoc(response.lastDoc)
			if (paginating === true) {
				setAssets([...assets, ...response.data])
			} else {
				setAssets(response.data)
			}
		} catch (error) {

		}
		setPaginating(false)
		setShowLoader(false)
	}

	async function onPressUpload() {
		setShowLoader(true)
		try {

			const assetId = Actions.generateIDForPath('assets')
			const __newAsset = {
				...newAsset,
				id: assetId
			} as Assets.Asset | Partial<Assets.Asset>
			if (newAsset?.thumbnail?.startsWith('data')) {

				console.log('Uploading Assets')

				const thumbnailResponse = await Assets.uploadAsset(assetId, newAsset.thumbnail)

				console.log('Uploading Thumbnail Medium')

				const thumbnailMediumResponse = await Assets.uploadAsset(assetId, newAsset.thumbnailMedium, '_medium')

				console.log('Uploading Thumbnail Large')

				const thumbnailLargeResponse = await Assets.uploadAsset(assetId, newAsset.thumbnailLarge, '_large')

				console.log('Uploading Full Size Image')

				const imageResponse = await Assets.uploadImage(assetId, newAsset.originalImage)

				console.log('Images Uploaded')

				__newAsset.thumbnail = thumbnailResponse.downloadUrl
				__newAsset.thumbnailMedium = thumbnailMediumResponse.downloadUrl
				__newAsset.thumbnailLarge = thumbnailLargeResponse.downloadUrl
				__newAsset.originalImage = imageResponse.downloadUrl

				console.log('Asset Created', __newAsset)

				const response = await Assets.create(__newAsset)
				setAssets([response, ...assets])

				setNewAsset(undefined)
			}


		} catch (error) {
			console.log('Error Uploading Images', error)
			Alert.alert('Error Uploading Image', String(error))
		}
		setShowLoader(false)
	}

}


function ImageItem(props: {
	item: Assets.Asset
}) {

	const { item } = props
	const [showButtons, setShowButtons] = useState(false)

	return (
		<TouchableOpacity style={{
			padding: '1em'
		}}>
			<div onMouseLeave={() => setShowButtons(false)} onMouseOver={() => setShowButtons(true)} style={{
				display: 'flex',
				border: '1px solid #dddddd',
			}}>
				<Image style={{
					width: 250,
					height: 250,
				}} source={{
					uri: item.thumbnailLarge
				}} />
				{showButtons === true && <View style={{
					flexDirection: 'row',
					position: 'absolute',
					bottom: 20,
					left: 10,
					right: 10,
					justifyContent: 'space-around'
				}}>
					<a href={item.thumbnail} target='_blank' rel="noreferrer" >
						<button style={{ color: 'black', ...styles.button }}>S</button>
					</a>
					<a href={item.thumbnailMedium} target='_blank' rel="noreferrer" >
						<button style={{ color: 'black', ...styles.button }}>M</button>
					</a>
					<a href={item.thumbnailLarge} target='_blank' rel="noreferrer" >
						<button style={{ color: 'black', ...styles.button }}>L</button>
					</a>
					<a href={item.originalImage} target='_blank' rel="noreferrer" >
						<button style={{ color: 'black', ...styles.button }}>O</button>
					</a>
				</View>}
			</div>
		</TouchableOpacity>
	)
}