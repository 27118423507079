/* eslint-disable react-hooks/exhaustive-deps */
import { View, Text, FlatList } from 'react-native'
import React, { useEffect, useState } from 'react'
// import { Support } from 'cs-connect'
import { useHistory, useParams } from 'react-router-dom'
import { Alert } from './utils/Alert.web'
import Progress from './utils/Progress'
import Paper from '@material-ui/core/Paper'
import TextField from './utils/TextField'
import {
	Analytics, Support
} from 'mcore'

export default function Chats() {

	const params = useParams() as {
		chatId: string
	}
	const chatId = params.chatId
	const [chat, setChat] = useState<Analytics.MError>()
	const [showLoader, setShowLoader] = useState(false)
	const [text, setText] = useState('')
	const [messages, setMessages] = useState<Analytics.Comment[] | Support.Message[]>([])
	const history = useHistory()

	useEffect(() => {
		if (chatId) {
			fetchChat()
		}
	}, [])

	async function fetchChat() {

		console.log('Fetching Chat ...')
		setShowLoader(true)
		try {
			const response = await Analytics.getBugReportWithId(chatId)
			const chat = response.data[0]

			await Support.updateChat(chatId, {
				'unread.support': 0
			} as any)

			setChat(chat)
		} catch (error) {
			console.error('Error Getting Chat', error)
			Alert.alert('Error Getting Chat', String(error))
		}
		setShowLoader(false)
	}

	useEffect(() => {
		if (chat != null && messages.length === 0) {
			if (chat.comments != null) {
				setMessages(chat.comments ?? [])
			} else {
				Support.getMessages(chat.id)
					.then(
						(response) => {
							setMessages(response.data)
						}
					)
					.catch((error) => {
						console.log('Error Getting Messages ', error)
					})
			}
		}
	}, [chat])


	if (showLoader === true) return <Progress />

	return (
		<View style={{
			padding: 15
		}}>
			<View style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}>
				<h2>Open Chat</h2>
				<button onClick={onPressMarkAsUnread}>Mark As Unread</button>
			</View>
			<View style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginVertical: 10
			}}>
				<View>
					<Text style={{
						marginVertical: 2
					}}>Chat Id: {chat?.id}</Text>
					<Text style={{
						marginVertical: 2
					}}>{chat?.createdBy.displayName ?? 'No Name Found'}</Text>
					<Text style={{
						marginVertical: 2
					}}>{chat?.createdBy.email}</Text>
					<Text style={{
						marginVertical: 2
					}}>Title: {chat?.title}</Text>
					<Text style={{
						marginVertical: 2
					}}>Description: {chat?.description}</Text>
				</View>
			</View>
			<Paper style={{
				padding: 15
			}}>
				<FlatList
					style={{
						minHeight: window.innerHeight * 0.6
					}}
					ListEmptyComponent={() => (
						<View>
							<Text>No Messages</Text>
						</View>
					)}
					data={messages.sort((a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime())}
					renderItem={renderItem} />
			</Paper>
			<View style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginVertical: 10
			}}>
				<TextField
					style={{
						flex: 1,
						marginRight: 10
					}}
					value={text}
					onChangeText={(value) => {
						setText(value)
					}} />
				<button
					style={{
						width: 150,
						height: 50
					}}
					onClick={onClickSubmit}>Send</button>
			</View>
		</View>
	)

	function renderItem({ item }: { item: Analytics.Comment }) {
		return (
			<View>
				<Text style={{
					padding: 15,
					backgroundColor: item.createdBy?.uid === chat?.createdBy.uid ? '#5eb6e6' : '#dddddd',
					borderRadius: 30,
					color: item.createdBy?.uid === chat?.createdBy?.uid ? 'white' : 'black',
					fontSize: 14,
					marginVertical: 5,
					flex: 1,
					maxWidth: '80%',
					alignSelf: item.createdBy?.uid !== chat?.createdBy?.uid ? 'flex-end' : 'flex-start'
				}}>{item.text}</Text>
			</View>
		)
	}

	async function onPressMarkAsUnread() {
		setShowLoader(true)
		try {
			await Support.updateChat(chatId, {
				'unread.support': 1
			} as any)
			history.goBack()
		} catch (error) {

		}
		setShowLoader(false)
	}

	async function onClickSubmit() {
		try {
			if (text.trim().length > 0) {

				if (chat?.comments != null) {
					await Analytics.addComment(chatId, text)
				}
				const response = await Support.addMessage(chatId, {
					text: text
				} as Support.Message)
				setMessages([...messages, response as Support.Message])
				setText('')
			}
		} catch (error) {
			Alert.alert('Error Sending Message', String(error))
			console.log('Error Sending Message : ', error)
		}
	}
}